import '../../styles/PrivacyPage.css';
const TermsPage = () => {
    return ( <>
    <div className="terms container px-6 py-10 mx-auto">
    <h3>Terms & Condtion</h3>
    <p>
        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Omnis neque fugit commodi ipsa, 
        incidunt praesentium dolores itaque earum quod excepturi impedit reiciendis officiis ab animi cum nihil totam laboriosam dolorem.
    </p>
    </div>
    
    </> );
}
 
export default TermsPage;