import axios from 'axios';
import React,{useContext} from 'react';
import { usePaystackPayment } from 'react-paystack';
import { UserContext, UserDispatchContext } from '../Homepage/UserProvider';

export const sumHasLitre = (perprice,guestno,litre) => {
    let unitPrice = perprice;
    let expectedGuest = guestno;
    let perlitre = litre;
    let totalLitre = expectedGuest* perlitre 
    let totalPrice = (unitPrice*totalLitre)/perlitre

    return totalPrice

};

export const sumTotal = (arr) => {
    const totalPrice = arr.reduce(
        (sum, currentState, index) => {
          if (currentState.added) {
            if(currentState.litre){

                let  litreSum = sumHasLitre(currentState.price,currentState.value,currentState.litre)
                 return sum + litreSum;
              }else{
                return sum + (currentState.price*currentState.value);
              } 
           
          }
          return sum;
        },
        0
      );
      return totalPrice
}
//
export const convert24hourTo12HourFormat = (time) => {
    const time_part_array = time.split(":");
    let ampm = 'AM';
    if (time_part_array[0] >= 12) {
        ampm = 'PM';
    }
    if (time_part_array[0] > 12) {
        time_part_array[0] = time_part_array[0] - 12;
    }
    const formatted_time = `${time_part_array[0]}:${time_part_array[1]} ${ampm}`;
    return formatted_time;
  }

  export const sendCartData = async (items,callback) => {
   
   return axios(
    {
      method: 'post',
      headers:{'Content-Type':'application/json'},
      url: 'https://gracious-jackson-fddbc3.netlify.app/.netlify/functions/api/cart',
      data:JSON.stringify(items)
    }
    )
    
  }
//https://gracious-jackson-fddbc3.netlify.app
  export const VerifyTransaction = (id,cartItems,token,selectedPercent,userObject,callback) => {
    axios(
      {
        method: 'post',
        headers:{'Content-Type':'application/json'},
        url: 'https://gracious-jackson-fddbc3.netlify.app/.netlify/functions/api/verify',
        data:JSON.stringify({"id":id,"cart":cartItems,"token":token,"selectedPercent":selectedPercent,"userObject":userObject})
      }
      )
      .then(function (response) {
        console.log(response);
        if(response.status === 200){
          callback('/success')
        }
      })
      .catch(function (error) {
        console.log(error);
      callback("/error")
      });
    }
  
  