import '../../styles/ProductSection.css';
import {ProductItem, ProductItem3} from './PoductItem';
import ProductPrice from './ProductPrice';
import GuestNo from './GusetNo';
import products from '../utils/Product';
import { useState } from 'react';
import { Button4 } from '../Homepage/Button';
import { useNavigate } from 'react-router-dom';
import React , { useContext } from 'react';
import { UserContext, UserDispatchContext } from '../Homepage/UserProvider';
import Modal from './Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {ArrowForward}  from '@styled-icons/material'
import UserDetails from '../Cart/UserData';

const Productsection = (props) => {
  const isMobile = Math.min(window.screen.width, window.screen.height) < 768 || navigator.userAgent.indexOf("Mobi") > -1;
  const UserDetails = React.useContext(UserContext);
    const setUserDetails = useContext(UserDispatchContext)

    const navigate = useNavigate()
    const [checkedState, setCheckedState] = useState(
        products
      );
  
    const submitHandler = () => {
      let count = 0;
        const validFields = ['name','event_type','event_date', 'event_location','phone','event_time']
        for(let key in UserDetails){
               
         if(UserDetails.hasOwnProperty(key)&& validFields.includes(key) && UserDetails[key]){
             if(UserDetails.cartItems.length >= 1){
                 count ++
             }
            }
     } 
     
    if(count <6) {setUserDetails((prevState) =>({...prevState,err:true})) }else{navigate('/cart')}
    }
  
      const [total, setTotal] = useState(0);
  
      const [cart, setCart] = useState([]);
  
      const sumHasLitre = (perprice,guestno,litre) => {
        let unitPrice = perprice;
        let expectedGuest = guestno;
        let perlitre = litre;
        let totalLitre = expectedGuest* perlitre 
        let totalPrice = (unitPrice*totalLitre)/perlitre
       
        return totalPrice
    
    };
    
      const handleOnChange = (id,value) => {
      value = Number(value);
      //console.log(typeof(checkedState),'ll'); 
         checkedState.forEach((item, index) =>{
          if(item.id === id){
              if(value >0){
                item.added = true
                item.value = value
              }else{
                item.added = false 
              }
              
          }
          
        });
        let updatedCheckedState = checkedState

    
        setCheckedState(updatedCheckedState);
  
    const totalProductAdded = checkedState.filter(e=>{return e.added === true})
    setCart(totalProductAdded)  
   
      
      const totalPrice = totalProductAdded.reduce(
        (sum, currentState, index) => {
          if (currentState.added) {
            if(currentState.litre){
               console.log(currentState.litre)
                let  litreSum = sumHasLitre(currentState.price,currentState.value,currentState.litre)
                
                 return sum + litreSum;
              }else{
                return sum + (currentState.price*currentState.value);
              } 
           
          }
          return sum;
        },
        0
      );
      setTotal(totalPrice);
     window.localStorage.setItem('cartItems', JSON.stringify(cart));
      setUserDetails((prevState) =>({...prevState,price:totalPrice,cartItems:totalProductAdded}))
    // let sumTotal = 0;
    // totalProductAdded.forEach(item=>{
    //     sumTotal += item.price*item.value
    // })
    //  console.log(sumTotal)
    // setTotal(sumTotal);
    
   
      
      };
    
    return ( <>
    <div className="product-body ">
      <div className='container px-6 py-10 mx-auto '>
       <div className='w-full flex justify-center items-center pb-10'>
           <div className='w-full md:w-3/4'><h3 className='text-white'>MENU</h3></div>
          <div className='w-full md:w-1/4'><h3 className='text-white'>UNIT PRICE</h3></div> 
           <div className='w-full md:w-1/4'><h3 className='text-white'>NUMBER OF SERVINGS</h3></div>
       </div>
       <div>
         {isMobile && products.map((item,index)=>{
           return(
             <div key={index}>
                <ProductItem3 name={item.name} price={item.price} total={total} id={item.id} initialValue={item.value} onChange={handleOnChange} /> 
             </div>
           )
         })}
        {!isMobile && products.map((e,j)=>{
          
            return(
                <div className='w-full flex justify-center items-center' key={j}>
                <div className='w-full md:w-3/4 product-item'><ProductItem name={e.name} /></div>
               <div className='w-full md:w-1/4 product-item'><ProductPrice  price={Number(e.price).toLocaleString('en-US')} /></div> 
                <div className='w-full md:w-1/4 product-item'><GuestNo total={total} id={e.id} initialValue={e.value} onChange={handleOnChange}/></div>
            </div> 
            )
        })}
       </div>
       <div className=' custom-button flex item-center justify-center '>
           <Button4 bg onClick={()=>navigate('/')}><i class="bi bi-arrow-left" style={{fontSize: '2rem', color: '#fff'}}></i> GO BACK</Button4>
           <Button4 onClick={()=>submitHandler()} >CONTINUE<span >< i class="bi bi-arrow-right" style={{fontSize: '2rem', color: '#fff'}}></i></span></Button4>
          
       </div>
       
      </div>
    </div>
    <Modal title="Your Cart is empty"  text="Please add a product to cart to continue"/>
    </> );
}
 
export default Productsection;