import Logo from '../../public/images/Adlat_logo .png';
import "../../styles/CartHero.css";
import cartHeroBg from '../../public/images/productBg.png';
import checkoutHero from '../../public/images/checkoutHero.png'
const CartHero = (props) => {
const imageBg = props.imageBg?checkoutHero:cartHeroBg
    return (<>
    
    <div className='cart-hero ' style={{backgroundImage:`url(${imageBg})`}}>
       <img src={Logo} alt="Adlat Logo"/>
        <div>
            <h3 className='menu-title' style={{color:props.bg?'#191510':'#fff'}}>{props.title?props.title:'CHECKOUT'}</h3>
        </div>
    </div>
    </>  
    );
}
 
export default CartHero;