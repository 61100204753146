import '../../styles/PrivacyPage.css';
const PrivacyPage = () => {

    return (  <>
    <div className="terms container px-6 py-10 mx-auto">
    <h3>Privacy & Policy</h3>
    <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. 
        Neque ex expedita quae fugiat sequi eligendi nesciunt eos minus ipsam voluptate non in reiciendis quos placeat, recusandae, corporis, ad
         minima nam!
    </p>
    </div>
    
    </>);
}
 
export default PrivacyPage;