import CartHero from "./CartHero";
import Footers from "../Homepage/Footers";
import SelectField from "../Homepage/SelectField";
import '../../styles/Payment.css'
const Payment = (props) => {
    return (  <>
    <CartHero imageBg title="MAKE PAYMENT" />
    <div className="payment-bg container px-6 py-10 mx-auto  ">
     <h3>TOTAL AMOUNT TO BE PAID</h3>  
     <SelectField  options={['Mobile transfer']}/>
    </div>
    <Footers />
    </>
    
    );
}
 
export default Payment;