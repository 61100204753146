import MenuSection from "../Homepage/MenuSection";
import Logo from '../../public/images/Adlat_logo .png';
import '../../styles/ProductHero.css'
const ProductsHero = (props) => {
    return (  <>
    <div className="product-hero">
     <img src={Logo} alt="Logo" />
     <MenuSection title={props.title?props.title:"CHOOSE FROM OUR MENU"} />
    </div>
    </>
    );
}
 
export default ProductsHero;