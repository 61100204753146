import '../../styles/Radio.css';
import React,{useContext} from 'react';
import { UserContext, UserDispatchContext } from '../Homepage/UserProvider';

const Radio = (props) => {
    const UserDetails = React.useContext(UserContext);
    const setUserDetails = useContext(UserDispatchContext);

    const sumPercentage = (e) => {
    const totalPrice = UserDetails.price;
    const validOption = ['25','50','100'];
     if(validOption.includes(e.target.value)){
        const percentPrice = totalPrice * Number(e.target.value)/100;
        setUserDetails((prevState)=>({...prevState,percentPrice,selectedPercent:e.target.value}))
     }
    }
    return ( <>
    <div class="container radio">
  
  <h2>Part-payment options:</h2>
  <div className='flex'>
  <label class="form-controls">
    <input type="radio" name="radio" value="25" onClick={sumPercentage} />
    25%
  </label>

  <label class="form-controls">
    <input type="radio" name="radio" value='50' onClick={sumPercentage} />
    50%
  </label>
  <label class="form-controls">
    <input type="radio" name="radio" value="100" onClick={sumPercentage}/>
    100%
  </label>
  </div>
  
  </div>
    </> );
}
 
export default Radio;