import React from 'react';
import './App.css';
import './styles/output.css';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import Homepage from './components/Homepage/Homepage';
import Product from './components/Products/Products';
import Menu from './components/Menu/Menu';
import Carts from './components/Cart/Cart';
import Checkout from './components/Cart/Checkout';
import Payment from './components/Cart/Payment';
import PaymentSuccess from './components/Cart/PaymentSuccess';
import TermsPage from './components/Terms and condition/Terms';
import PrivacyPage from './components/Terms and condition/PrivacyPage';
import MyLoader from './components/Cart/OvelayLoading';
import Error from './components/Cart/Error';
import { UserContext, UserDispatchContext } from './components/Homepage/UserProvider';

function App() {
  let userDetails = React.useContext(UserContext);
  return (
    <div >
      <BrowserRouter>
      <Routes>
        
        <Route path="products" element={<Product />}/>
        <Route path='menus' element={<Menu/>}/>
        <Route path='/checkout' element={<MyLoader active={userDetails.loading} children={<Checkout/>} />}/>
        <Route path='/cart' element={<Carts/>}/>
        <Route  path='/payment' element={<Payment />} />
        <Route exact path='/success' element={<PaymentSuccess />} />
        <Route exact path='error' element={<Error />} />
        <Route path='/terms-condition' element={<TermsPage/>} />
        <Route path='/privacy' element={<PrivacyPage/>}/>
        <Route path='*' element={<Homepage />} />
        <Route path='/' element={<Homepage />} />
      </Routes>
      </BrowserRouter>
     
     
    </div>
  );
}

export default App;
