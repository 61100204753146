import icon1 from '../../public/images/icon1.png';
import '../../styles/FeaturesCard.css'
const FeaturesCard = (props) => {
    return ( <>
       <div class="flex flex-col items-center p-6 space-y-3 text-center ">
                        <span class="inline-block p-3 text-blue-500  rounded-full dark:text-white ">
                            <img src={props.src?props.src:icon1} />
                        </span>

                        <h1 class="features-title ">{props.title?props.title:'QUALITY FOODS'}</h1>

                        <p class="features-body ">
                            {props.text?props.text:'Our meals are prepared by our professional cooks using fresh foodstuff from the best market.'}
                        </p>
                    </div>
    </> );
}
 
export default FeaturesCard;