import { ProductItem2 } from "../Products/PoductItem";
import products from "../utils/Product";
import '../../styles/Menu.css';
import Footers from "../Homepage/Footers";
import MenuSection from "../Homepage/MenuSection";
import ProductsHero from "../Products/ProductsHero";
const Menu = (props) => {
    return (<>
   
    <ProductsHero title={'OUR MENU'}/>
    <div style={{'background':'#1C1B1A'}}>
    <div className="container px-6 py-10 mx-auto  menu">

<h3>NIGERIAN FOODS</h3>
{products.map((e,j)=>{
    if(e.catergory === "NIGERIAN FOODS"){
        return (
           <ProductItem2 name={e.name} price={e.price.toLocaleString('en-US')}/>
        )
       }
        
        
})}
<h3>SOUPS</h3>
{products.map((e,j)=>{
    if(e.catergory === "SOUPS"){
       return (
           <ProductItem2 name={e.name} price={e.price.toLocaleString('en-US')}/>
        )
    }
})}
<h3>PASTRIES</h3>
{products.map((e,j)=>{
    if(e.catergory === "PASTERIES"){
       return (
           <ProductItem2 name={e.name} price={e.price.toLocaleString('en-US')}/>
        )
    }
})}
</div>
    </div>
    
    <Footers />
    </> 
     );
}
 
export default Menu;