import LoadingOverlay from '@ronchalant/react-loading-overlay';
import React from 'react';
import FadeLoader from 'react-spinners/FadeLoader';
import { UserContext } from '../Homepage/UserProvider';
export default function MyLoader({ active, children }) {
    let UserDetails = React.useContext(UserContext)
    return (
      <LoadingOverlay
        active={active}
        text={<p style={{marginTop:'100px'}}>{UserDetails.loadingText}</p>}
        spinner={<FadeLoader />}
      >
          <div>
          
        {children}
          </div>
          
      </LoadingOverlay>
    )
  }