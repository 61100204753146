import React,{useEffect,useContext, useState} from "react";
import MenuSection from "../Homepage/MenuSection";
import Footers from "../Homepage/Footers";
import ProductsHero from "./ProductsHero";
import Productsection from "./ProductSection";
import { UserContext, UserDispatchContext } from '../Homepage/UserProvider';
import { useNavigate } from "react-router-dom";
const Products = () => {

  const [err,setErr] = useState(false)
  const navigate =useNavigate();
   const UserDetails = React.useContext(UserContext);


   useEffect(()=>{
    let count = 0;
    const validFields = ['name','event_type','event_date', 'event_location','phone','email','event_time']
    for(let key in UserDetails){
           
     if(UserDetails.hasOwnProperty(key)&& validFields.includes(key) && UserDetails[key]){
         count++
            }
 } 
 
if(count <7) { navigate('/')}
    
   })

  
    return ( <>
     <div>
      <ProductsHero />  
      
      <Productsection />
      
      
     
      <Footers/>
    </div>
    </>
     );
}
 
export default Products;