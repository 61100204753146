import '../../styles/Cart.css'
const ServiceLogistics = (props) => {
    return (<>
    
    <h3>SERVICE AND LOGISTICS FEE</h3>
    <div className='cart-card py-6'>
        <div><p className="cart-title">SERVICE FEE(10% OF TOTAL FOOD COST):</p><p className="cart-subtitle">{props.serviceFee || '₦4200'}</p></div>
        <div><p className="cart-title">LOGISTICS (WITHIN LAGOS):</p> <p className="cart-subtitle">{props.logisticsFee || '₦5000'}</p></div>
        <div><p className="cart-title">TOTAL COST:</p> <p className="cart-subtitle">{props.serviceTotal || '₦47000'}</p> </div>
    </div>
    </>  
    );
}
 
export default ServiceLogistics;