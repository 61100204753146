import foodImage1 from '../../public/images/Group 183.png';
import chefGroup from '../../public/images/chefgroup.png';
import chefGroup2 from '../../public/images/chefgroup2.png'
import '../../styles/CtaSection.css';
import { Button3 } from './Button';
import food from '../../public/images/food.png';
import { useNavigate } from 'react-router-dom';
const CtaSection1 = () => {
  const navigate = useNavigate();
  const scrollTop = ()=>{
       
    window.scrollTo({
        top: 0, 
        behavior: 'smooth'
       
      })}
  const isMobile = Math.min(window.screen.width, window.screen.height) < 768 || navigator.userAgent.indexOf("Mobi") > -1;
   return ( <>
    <section class="cta1_container text-gray-600 body-font">
  <div class="container px-6 py-10 mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
    <div class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
      <h1 class=" cta_text sm:text-4xl text-3xl mb-4  font-Antonio font-bold text-black-900">THE ADLAT MAGIC
        
      </h1>
     {isMobile && <img class="object-cover object-center rounded" alt="hero" src={foodImage1} />}
      <p class="cta_p font-antonio mb-8 leading-relaxed text-xl font-normal">Talk about good food, talk about premium service, talk about Adlat. We are not just another catering service, we offer the best. Our service is not one you’ll forget in a lifetime.</p>
      <div class="flex justify-center">
        <button class=" cta_button inline-flex text-white bg-indigo-500 border-0 py-10 px-10 focus:outline-none hover:bg-indigo-600 rounded text-lg" onClick={()=>scrollTop()}>MAKE BOOKINGS</button>
        
      </div>
    </div>
    <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
    {!isMobile && <img class="object-cover object-center rounded" alt="hero" src={foodImage1} />}
    </div>
  </div>
</section>
    </> 
    );
}

const CtaSection2 = () => {
  const isMobile = Math.min(window.screen.width, window.screen.height) < 768 || navigator.userAgent.indexOf("Mobi") > -1;
  const chefGroupImage = isMobile ?chefGroup2:chefGroup
   return (<>
       <div className='crew container px-6 py-10 mx-auto flex flex-wrap'>
       <h3 className='md:text-xs'>{isMobile && 'THE ADLAT CREW'}</h3>
        <div className='w-full md:w-2/4 h-56 crew-image '><img className='max-w-full h-auto ' src={chefGroupImage} alt="ChefGroup"/></div>
        <div className='w-full md:w-2/4 flex flex-col flex-1 '>
            <h3 className='text-lg'>{!isMobile && 'THE ADLAT CREW'}</h3>
            <p>Adlat Catering Service was born out of the necesity to make quality food accessible to people during their events and whenever they need it. Since the inception of Adalt Catering, we have been able to give our clients the premium service they deserve.</p>
            <p>Our team is made up of the best hands to prepare your meals with love and serve you with respect that royalty deserve because at Adlat, we treat our clients as Kings and Queens.</p>
        </div>
       </div>
    </> 
     );
}
 
const CtaSection3 = () => {
  const scrollTop = ()=>{
       
    window.scrollTo({
        top: 0, 
        behavior: 'smooth'
       
      })}
  return (  <>
    <div className='cta_3-container container px-6 py-10 mx-auto'>
      <img alt="Food" src={food}/>
      <p>Do you have an event coming up and you need the best hands to cater for your guests? Or you need a hands-on catering service for your personal needs? Click the button below to make a booking and our sales rep will reach out to you immediately!</p>
      <Button3 onClick={scrollTop} />
    </div>
  </> 
   );
}
 

 
export { CtaSection1, CtaSection2, CtaSection3};