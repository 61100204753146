import React, { memo } from "react";
import products from "../utils/Product";
import { UserContext } from '../Homepage/UserProvider';
const GuestNo = (props) => {
  const UserDetails = React.useContext(UserContext);
  let value = UserDetails.cartItems.filter((item)=>item.id === props.id
  )
  let initialValue
  if(value[0]){
    
    initialValue = value[0]['value']
  }else{
    initialValue = 0
  }
 
 
    return ( <>
      <div className="container ">
         
          <input className=" guestNo px-6 py-10 rounded" id={props.id} type="text" value={initialValue}  onChange={(e)=>props.onChange(e.target.id,e.target.value)} />
      </div>
    </> );
}
 
export default memo(GuestNo);