import '../../styles/MenuSection.css'
import bgmenu from '../../public/images/bgmenu.png'
import soup from '../../public/images/soup.png'
import ng from '../../public/images/ng.png'
import pastries from '../../public/images/pastries.png'
const MenuSection = (props) => {
    return (<>
    <div className='bg-menu ' style={{background:props.bg?bgmenu:'transparent'}}>
        <div>
            <h3 className='menu-title' style={{color:props.bg?'#191510':'#fff'}}>{props.title?props.title:'OUR MENU'}</h3>
            <p className='menu-subtitle'>{props.text?props.text:''}</p>
        </div>
        <div className='food-div' style={{}}>
            <img src={ng}  alt="Nigeria Food"/>
            <img src={soup}  alt="Soups"/>
            <img src={pastries} alt="Pasteries"/>
        </div>
    </div>
    </>  
    );
}
 
export default MenuSection;