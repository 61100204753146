import React from 'react';
import {useNavigate} from 'react-router-dom' ;
import '../../styles/Button.css';
import Arrow1 from  '../../public/images/Arrow_1.png';
import Arrow2 from '../../public/images/Arrow_2.png';
//import BtnBg from '../../public/images/btn_bg.png'

import styled from 'styled-components';

export const Button1 = ()=>{
   return(
       <div className='btn1'>
       <a class="button triangle1 my-4" href="#booking">
       ENJOY FIRST-TIMER DISCOUNT
</a>
       </div>
   )
};

export const Button2 = (props)=>{
    const navigate = useNavigate()
    const menuHandler = () => {
    navigate('/menus')
    }
    return(
        <div className="btn2">
         <button className="button my-4"  onClick={menuHandler}><span>EXPLORE OUR MENU</span></button>
        </div>
    )
 };

 export const Button3 = (props) => {
   
     return(
         <>
         <div className='btn3_container'>
         <img src={Arrow1} />    
         <div class="flex justify-center">
        <button class=" cta_button inline-flex text-white bg-indigo-500 border-0 py-10 px-10 focus:outline-none hover:bg-indigo-600 rounded text-lg" id='btn-continue' onClick={props.onClick}>MAKE BOOKINGS</button>
        
      </div>
      <img src={Arrow2} />  
         </div>
         </>
     )
 }
 export const Button6 = (props) => {
    const navigate  = useNavigate()
    
     const submitHandler = () => {
     const toPage = props.link?props.link:'/products';
     navigate(toPage)
     }
      return(
          <>
          <div className='btn3_container m-2.5'>
          
          <div class="flex justify-center">
         <button class=" cta_button inline-flex text-black bg-indigo-500 border-0 py-10 px-10 focus:outline-none hover:bg-indigo-600 rounded text-lg" id='btn-continue' onClick={submitHandler}>{props.title?props.title:'MAKE BOOKINGS'}< i class="bi bi-arrow-right" style={{fontSize: '2rem', color: 'black',marginLeft:'12px'}}></i></button>
         
       </div>
        
          </div>
          </>
      )
  }
  export const Button4 = styled.div`
  font-family: 'Sora';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.02em;
        padding: 1rem 2.5rem;
        color: #FFFFFF; 
        width:250px;  
        text-align:center;
        display:flex;
        justify-content:space-between;
        align-items:center;
        cursor:pointer;
    background: ${props => props.bg ? "transparent":"linear-gradient(274.44deg, #F7971E 7.18%, #FFD200 140.03%)"};
    border: 1.5px solid #FFFFFF;
    border-radius: 4px;
    margin:15px 20px;
    
    
  `;
  export const Button5 = styled.div`
  font-family: 'Sora';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.02em;
        padding: 1rem 2.5rem;
        color: #1C1B1A;   
        width:auto;  
        text-align:center;
        display:flex;
        justify-content:space-between;
        align-items:center;
        cursor:pointer;
        margin:5px 20px;
    background: ${props => props.bg ? "transparent":"linear-gradient(274.44deg, #F7971E 7.18%, #FFD200 140.03%)"};
    border:${props => props.border?'2px solid #1C1B1A':'1.5px solid #FFFFFF'} ;
    border-radius: 4px;
    @media (max-width: 480px) {
        font-size 12px;
        padding: 1rem 1rem;
        justify-content:center;
        align-items:center;
    margin-bottom:20px;
      }
  `;
  