import socialproof1 from '../../public/images/socialproof.png';
import socialproof2 from '../../public/images/socialproof2.png';
import '../../styles/SocialProof.css';
const SocialProof1 = () => {
   const isMobile = Math.min(window.screen.width, window.screen.height) < 768 || navigator.userAgent.indexOf("Mobi") > -1;
   const socialProof = isMobile ?socialproof2:socialproof1
    return (<>
    <div className='socialproof'>
       <h4 className='socialproof-title'>WE ARE TRUSTED BY</h4> 
       <img src={socialProof}/>
    </div>
    </> 
     );
}
 
export default SocialProof1;