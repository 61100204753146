import Footers from '../Homepage/Footers';
import {  Button3, Button5, Button6 } from '../Homepage/Button';
import ServiceLogistics from './ServiceLogistics';
import CartCard from './CartCard';
import UserData from './UserData';
import React , { useContext,useEffect } from 'react';
import { UserContext, UserDispatchContext } from '../Homepage/UserProvider';
import '../../styles/Cart.css'
import { useNavigate } from 'react-router-dom';
import CartHero from './CartHero';
import {  sumTotal , convert24hourTo12HourFormat } from '../utils/Helper';
const Carts = () => {
    
    const navigate = useNavigate();
    const UserDetails = React.useContext(UserContext);
    //console.log(UserDetails)
    //const setUserDetails = useContext(UserDispatchContext);
    useEffect(()=>{
        let count = 0;
        const validFields = ['name','event_type','event_date', 'event_location','phone','email','event_time']
        for(let key in UserDetails){
               
         if(UserDetails.hasOwnProperty(key)&& validFields.includes(key) && UserDetails[key]){
             if(UserDetails.cartItems.length >= 1){
                 count ++
             }
            }
     } 
     
    if(count <7) { navigate('/products')}
    
    })
      const totalPrice = sumTotal(UserDetails.cartItems)
    const totalFoodCost = totalPrice;
    const serviceFee = totalFoodCost * 0.1;
    const logisticsFee = 5000;
    const totalLogisticFee = serviceFee + logisticsFee;
    const TotalCost = totalFoodCost + totalLogisticFee
    const userProfile = {
        name: UserDetails.name,
        phone:UserDetails.phone,
        event_type:UserDetails.event_type,
        event_date : UserDetails.event_date,
        event_time : convert24hourTo12HourFormat(UserDetails.event_time),
        event_location : UserDetails.event_location,    
    }
    return ( <>
    <CartHero />
    <div className='cart-bg'>
    <div className='container px-6 py-10 mx-auto  '>
      <UserData {...userProfile}  />
      <h3>MENU DETAILS</h3>
      {UserDetails.cartItems.map((item,index)=>{
          let totalCost = item.value * item.price
          return(
              <div key={index}>
                  <CartCard  name={item.name} price={item.price} serves={item.value} totalCost={totalCost}  />
              </div>
              
          )
      })}
      <ServiceLogistics serviceFee={serviceFee} logisticsFee={logisticsFee} serviceTotal={totalLogisticFee} />
    <div className='cart-card' style={{background:'transparent',border:'none'}}>
    <h3>TOTAL COST</h3>
    <div><p className="cart-title">COST OF FOOD:</p> <p className="cart-subtitle">{`₦${totalFoodCost}` || '₦420,000'}</p></div>
    <div><p className="cart-title">SERVICE AND LOGISTICS:</p><p className="cart-subtitle">{`₦${totalLogisticFee}` || '₦47,000'}</p></div>
    <div><p className="cart-title">TOTAL COST:</p><p className="cart-subtitle">{`₦${TotalCost}` || '₦467,000'}</p> </div>
    </div>

    <div className=' custom-button flex item-center justify-center ' >
           <Button5 bg border onClick={()=>navigate('/products')}><i class="bi bi-arrow-left" style={{fontSize: '2rem', color: 'black', marginRight:'12px'}}></i> GO BACK</Button5>
           <Button5  onClick={()=>navigate('/checkout')} >  CONTINUE TO GET FIRST-TIMER DISCOUNT<span >< i class="bi bi-arrow-right" style={{fontSize: '2rem', color: 'black',marginLeft:'12px'}}></i></span></Button5>
           
       </div>
      
    </div>
    </div>
    <Footers/>
    </> );
}
 
export default Carts;