import Slider from "react-slick";
import food1 from '../../public/images/food1.png';
import food2 from '../../public/images/food2.png';
import food3 from '../../public/images/food3.png';
import food4 from '../../public/images/food4.png';
import food5 from '../../public/images/food5.png';
import '../../styles/ImageSlide.css';
const ImageSlide = () => {

    var settings = {
        
        dots: false,
        speed: 200,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay:true,
        mobileFirst: true,
        infinite:true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
              infinite: true,
              dots: false
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };

    return ( <>
    <div className="slide-image" style={{'overflow':'hidden'}}>
     <Slider {...settings} >
     <img  src={food1} alt="food "/>
     <img  src={food2} alt="food "/>
     <img  src={food3} alt="food "/>
     <img  src={food4} alt="food "/>
     <img  src={food5} alt="food "/>
    </Slider>   
    </div>
       
    </> );
}
 
export default ImageSlide;