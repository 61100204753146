import '../../styles/Hero.css';
import { Button1, Button2 } from './Button';
import AdlatLogo from '../../public/images/Adlat_logo .png';
import Booking from './Booking';
const Hero = () => {

    return ( 
    <>
   <div className="hero">
      <div><img  src={AdlatLogo} height="150" /></div>
      <div style={{display:'flex','flexDirection'
    :'column','alignContent':'center',alignItems:'center'}}>
        <h3 className="md:text-7xl font-bold title sm:text-4xl">EXPERIENCE PREMIUM FOOD SERVICE</h3>
        <p><h6 className="subtitle">Enjoy our first-timer discount when you make reservations for your upcoming event today!</h6></p>
      <div className="btn-group container px-6 py-10 mx-auto  flex justify-center items-center my-11 ">
          <Button1 />
          <Button2 />
      </div>
  
      </div>
   </div>
   <Booking />
    </> 
    
    );
}
 
export default Hero;