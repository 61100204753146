import React ,{useContext,useEffect,useState} from 'react';
import { UserContext, UserDispatchContext } from './UserProvider';

import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
import axios from 'axios';
const Input = (props) => {
  const UserDetails = React.useContext(UserContext);

  const setUserDetails = useContext(UserDispatchContext)
  const setInputvalue = (e) =>{
    if(e.target.name === 'email'){
      
      if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value)){
        e.target.style.borderColor = 'white';
        
        setUserDetails((prevState) =>({...prevState,[e.target.name]:e.target.value}))
      }else{
        e.target.style.borderColor = 'red';
        setUserDetails((prevState) =>({...prevState,[e.target.name]:''}))
      }
    }else{
      setUserDetails((prevState) =>({...prevState,[e.target.name]:e.target.value}))
    }
    

}
    return ( <>
    <div className="w-full  md:w-full  px-3 mb-6 md:mb-0">

      <input class=" md:w-full sm:w-full p-5 block placeholder-gray-900 placeholder-opacity-100 w-full bg-white-200 text-black-700 border border-white-500 py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" name={props.name} type={props.type} placeholder={props.placeholder} onChange={(e)=>setInputvalue(e)} />
      
    </div>
    </> 
    );
}
 
const Input2 = (props) => {
  const UserDetails = React.useContext(UserContext);

  const setUserDetails = useContext(UserDispatchContext)
  const [show,setShow] = useState(false);
  const [time,setTime] = useState('')
  const setInputvalue = (e) =>{
    console.log(e.target.value)
    setUserDetails((prevState) =>({...prevState,[e.target.name]:e.target.value}))

}

  return (<>
     {!show && <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
      
      <div class="relative">
        <input className=" form-control p-5 block appearance-none w-full bg-white-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8  leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id={props.id} name={props.name} type={props.type} placeholder={props.placeholder} value={time} onFocus={(e) => (setShow(true))}
         />
        
      </div>
    </div>
}
    {show && <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0" >
      
      <div class="relative">
        <input className=" form-control p-5 block appearance-none w-full bg-white-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8  leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id={props.id} name={props.name} type='time' placeholder={props.placeholder} onBlur={(e) => (setShow(false))}
         onChange={(e)=>{setInputvalue(e);setTime(e.target.value)}}/>
        
      </div>
    </div>}
  </>  );
}

const Input3 = (props) => {
 
const [phone, setPhone] = useState();
const [phoneErr , setPhoneErr] = useState(false);
  const UserDetails = React.useContext(UserContext);

  const setUserDetails = useContext(UserDispatchContext)
  useEffect(()=>{
    const input = document.querySelector("#phone");
    var iti = intlTelInput(input, {
      utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@14.0.3/build/js/utils.min.js',
      customPlaceholder: function (countryPlaceholder) {
          return  ''+ countryPlaceholder;
      },
      autoPlaceholder: 'aggressive',
      separateDialCode:true,
      onlyCountries:['ng']
    
  });
   setPhone(iti)
   
  },[])
  
  const setInputvalue = (e) =>{
    
    if(phone.isValidNumber()){
    setUserDetails((prevState) =>({...prevState,'phone':phone.getNumber()}))
    setPhoneErr(false)
    }else{
      setUserDetails((prevState) =>({...prevState,'phone':''}))
      setPhoneErr(true)
    }
    
   
  
}
    return ( <>
    <div className="w-full  md:w-full  px-3 mb-6 md:mb-0">

      <input class=" md:w-full sm:w-full p-5 block placeholder-gray-900 placeholder-opacity-100 w-full bg-white-200 text-black-700 border border-white-500 py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="phone" name="phone" type="phone" placeholder="Enter phone number" onChange={(e)=>setInputvalue(e)} />
     {phoneErr && <p style={{color:'red'}}>Invalid phone number</p>} 
    </div>
    </> 
    );
}
const Input4 = (props) => {
  const UserDetails = React.useContext(UserContext);

  const setUserDetails = useContext(UserDispatchContext)
  const setInputvalue = (e) =>{
    setUserDetails((prevState) =>({...prevState,[e.target.name]:e.target.value}))

}

  return (<>
  <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
      
      <div class="relative">
        <input className=" form-control p-5 block appearance-none w-full bg-white-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8  leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id={props.id} name={props.name} type={props.type} placeholder={props.placeholder}onFocus={(e) => (e.target.type = "time")}
        onBlur={(e) => (e.target.type = "text")} onChange={(e)=>setInputvalue(e)}/>
        
      </div>
    </div>
  </>  );
}
export { Input2, Input, Input3, Input4};