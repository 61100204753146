import { useState, useEffect } from "react";
import Features from "./Feaatures";
import { CtaSection1,CtaSection2, CtaSection3 } from "./CtaSection";

import Hero from "./Hero";
import MenuSection from "./MenuSection";
import SocialProof1 from "./SocialProof";
import Testimonial from "./Testimonial";
import Footers from "./Footers";
import Modal from "../Products/Modal";
import ImageSlide from "./ImageSlide";
const Homepage = () => {
 const [bg,setBg] = useState(true)
 const updateBg = useEffect(()=>{
    setBg(true)
 },[])
    return ( <>
   
     <Hero />
      
      <CtaSection1 />
      <MenuSection bg={bg} text="We have a variety of food in our menu for your events and personal needs." />
     <ImageSlide />
      <Testimonial />
      <SocialProof1 />
      <CtaSection2 />
      <Features />
      <CtaSection3 />
      <Footers />
      
    </> );
}
 
export default Homepage;