import FeaturesCard from "./FeaturesCard";
import '../../styles/Features.css';
import icon2 from '../../public/images/icon2.png';
import icon3 from '../../public/images/icon3.png';
const Features = () => {
    return ( <>
    
    <section class="features">
            <div class="container px-6 py-10 mx-auto">
               
                
                <div class="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-16 md:grid-cols-2 xl:grid-cols-3">
                    <FeaturesCard  />
                    <FeaturesCard  src={icon3} title={'FASTER DELIVERY'} text={'Delay is not a word we identify with. We keep to time and agreement, always.'}/>
                    <FeaturesCard src={icon2} title={'PREMIUM SERVICE'} text={'We treat our clients like royalty. As a royalty, you deserve the best and that’s exactly what we give'} />
                </div>
            </div>
        </section>
    </> 
    );
}
 
export default Features;