import React ,{useContext} from 'react';
import { UserContext, UserDispatchContext } from './UserProvider';

const SelectField = (props) => {
  const UserDetails = React.useContext(UserContext);

  const setUserDetails = useContext(UserDispatchContext)
  const setInputvalue = (e) =>{
    setUserDetails((prevState) =>({...prevState,[e.target.name]:e.target.value}))
 
}
    
     let items =   props.options.map((e,j)=>{
           return(<option key={j}>{e}</option>)
            
        } ) 
    
    return ( <>

     <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
      
      <div class="relative">
        <select className=" p-5 block appearance-none w-full bg-white-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8  leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state1" name={props.name} onChange={(e)=>setInputvalue(e)}> 
        {items}
        </select>
        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
        </div>
      </div>
    </div>
    </> 
    );
}
 
export default SelectField;