import '../../styles/ProductItem.css'
import GuestNo from './GusetNo';
const ProductItem = (props) => {
    return ( <>
      <div className="product-name w-full container px-6 py-10">
       <h4>{props.name?props.name.toUpperCase():'JOLLOF RICE & FRIED RICE & PLANTAIN (DODO) WITH TWO PROTEIN'}</h4>
      </div>
    </> );
}
const ProductItem2 = (props) => {
    return (<>
    <div className="product-name w-full container px-6 py-8 flex justify-between items-stretch mt-5 mb-5 ">
       <h4 className='w-full'>{props.name?props.name.toUpperCase():'JOLLOF RICE & FRIED RICE & PLANTAIN (DODO) WITH TWO PROTEIN'}</h4>
      <h4 className='price'>{props.price?'₦'+props.price:'₦'}</h4>
      </div>
    </>  );
}

const ProductItem3 = (props) => {
  return (<>
  <div className="product-name w-full container px-6 py-8 flex justify-center items-stretch mt-5 mb-5 ">
     <h4 className='w-full'>{props.name?props.name.toUpperCase():'JOLLOF RICE & FRIED RICE & PLANTAIN (DODO) WITH TWO PROTEIN'}</h4>
    <div className=' container px-6 py-6 mx-auto flex justify-center mx-auto'>
    <h4 className='price'>{props.price?'₦'+props.price:'₦3500'}</h4>
    <GuestNo id={props.id} intialValue={props.intialValue} onChange={props.onChange} />
    </div>
    
    </div>
  </>  );
}
 
export { ProductItem, ProductItem2, ProductItem3};