import TestimonialCard from "./TestimonialCard";
import '../../styles/Testimonial.css';

import Slider from "react-slick";

const Testimonial = () => {
    var settings = {
        
        dots: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay:true,
        mobileFirst: true,
        infinite:true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
              infinite: true,
              dots: false
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
    return (<>
        <div className="testimonial">
        <h3 className="testimonial-title sm:text-sm">HEAR FROM OUR PAST CLIENTS</h3>
            <div className="testimonial-body">
            <Slider {...settings}>
            <TestimonialCard />
            <TestimonialCard />
            <TestimonialCard />
            <TestimonialCard text="I’ve been a client of Alhaja for over 15 years now, I do not see myself getting any other caterer for my events except her. She’s simply the best hands for your events. I recommend her." name="Mrs. Bello H."/>
            <TestimonialCard name="Alhaja Sade Halle" text="I needed  a caterer to prepare the food I would eat during my brief stay in Nigeria. A friend recommended Adlat Catering Service to me. The service rendered was more than the price I paid. So top-notch!" />
             
            </Slider>
            
            </div>
            
            
        </div>
    </>
    );
}
 
export default Testimonial;