import '../../styles/Cart.css'
const UserDetails = (props) => {
    return ( <>
    
        <h3>YOUR DETAILS</h3>
        <div className='cart-card py-6'>
            
        <div><p className="cart-title">TYPE OF EVENT:</p><p className="cart-subtitle">{props.event_type || 'CORPORATE EVENT'}</p></div>
        <div><p className="cart-title">DATE OF EVENT:</p> <p className="cart-subtitle">{props.event_date || 'THURSDAY, 25TH OF JULY , 2022'}</p></div>
        <div><p className="cart-title">LOCATION OF EVENT:</p> <p className="cart-subtitle">{props.event_location || 'IBEJU LEKKI, LAGOS STATE'}</p></div>
        <div><p className="cart-title">TIME OF EVENT:</p><p className="cart-subtitle">{props.event_time || '11:00AM'}</p></div>
        <div><p className="cart-title">PHONE NUMBER:</p> <p className="cart-subtitle">{props.phone || '09032343443'}</p></div>
        <div><p className="cart-title">NAME:</p> <p className="cart-subtitle">{props.name || 'MR OLUFEMI OLAMIDE'}</p></div>
    </div>
    </> 
    );
}
 
export default UserDetails;