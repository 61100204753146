import CartHero from "./CartHero";
import Footers from "../Homepage/Footers";
import { Button5 } from "../Homepage/Button";
import '../../styles/Payment.css';
import { UserContext, UserDispatchContext } from '../Homepage/UserProvider';
import React,{ useEffect,useContext } from "react";
import { useNavigate } from "react-router-dom";
const PaymentSuccess = (props) => {
    
    const navigate = useNavigate()
    const UserDetails = React.useContext(UserContext);
      //console.log(UserDetails)
    const setUserDetails = useContext(UserDispatchContext);
    useEffect(()=>{
        setUserDetails({
            name: "",
            email:"",
            event_type:"Family Enagagement",
            event_date:"",
            event_location:"",
            phone:"",
            event_time:"",
            price:0,
            cartItems:[],
            err:false,
            percentPrice:0,
            selectedPercent:'',
            loading:false,
            loadingText:'Loading checkout widget'
          })
    },[])
    
    return ( <>
    <CartHero imageBg title="WE HAVE RECEIVED YOUR PAYMENT!"  />
    <div className="payment-bg container px-6 py-10 mx-auto flex justify-center ">
    <p>THANK YOU FOR MAKING THE PAYMENT. OUR SALES REP WILL REACH OUT TO YOU IN A COUPLE OF MINUTES FROM NOW. THANK YOU ONCE AGAIN!</p>
    <div className='custom-button flex item-center justify-center  py-8' >
           <Button5 bg border onClick={()=>navigate('/')}><i class="bi bi-arrow-left" style={{fontSize: '2rem', color: 'black', marginRight:'12px'}}></i> GO BACK</Button5>
           <a href="tel:+23408023467582"><Button5 >CALL SALES REP MYSELF<span >< i class="bi bi-telephone-outbound-fill" style={{fontSize: '2rem', color: 'black',marginLeft:'12px'}}></i></span></Button5></a>
           
       </div>
   </div>
    
    <Footers />
    </> );
}
 
export default PaymentSuccess;