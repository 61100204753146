import comma from '../../public/images/comma.png';
import '../../styles/TestimonialCard.css'
const TestimonialCard = (props) => {
    return (<>
    <div className='card-bg'>
        <div><img  src={comma} alt="comma"/></div>
        <p>{props.text?props.text:`I got introduced to Adlat Catering Services by a friend. The service rendered during my mom’s burial was top notch! My guests were beyond satisfied.
I recommend them anytime, anyday!`}</p>
        <h6>{props.name?props.name:'Barrister Aderibigbe E.'}</h6>
    </div>
    </> 
     );
}
 
export default TestimonialCard;