import '../../styles/Cart.css'
const CartCard = (props) => {
    return ( <>
    
    
    <div className="cart-card py-6">
        <div><p className="cart-title">FOOD:</p> <p className="cart-subtitle">{props.name || 'JOLLOF RICE'}</p></div>
        <div><p className="cart-title">UNIT PRICE OF ONE SERVING:</p> <p className="cart-subtitle">{props.price || '₦3500'}</p></div>
        <div><p className="cart-title">NUMBER OF SERVINGS:</p> <p className="cart-subtitle">{props.serves || '120'}</p></div>
        <div><p className="cart-title">TOTAL COST:</p><p className="cart-subtitle"> {props.totalCost|| '₦42,000'}</p></div>
    </div>
    
    </>
    )
}
 
export default CartCard;