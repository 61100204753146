import React,{useEffect,useContext,useState} from 'react'
import { UserContext, UserDispatchContext } from '../Homepage/UserProvider';
import CartHero from "./CartHero";
import checkoutHero from '../../public/images/checkoutHero.png';
import { Button5 } from "../Homepage/Button";
import Footers from "../Homepage/Footers";
import '../../styles/Cart.css'
import { useNavigate } from "react-router-dom";
import { usePaystackPayment } from 'react-paystack';
import { sumTotal,sendCartData ,VerifyTransaction,convert24hourTo12HourFormat} from "../utils/Helper";
import env from 'react-dotenv';
import Radio from './Radio';


const Checkout = (props) => {
    const navigate = useNavigate();
    const UserDetails = React.useContext(UserContext);
    const setUserDetails = useContext(UserDispatchContext);
    const key = env.PAYSTACK_PUBLIC_KEY;
  
    useEffect(()=>{
        let count = 0;
        const validFields = ['name','event_type','event_date', 'event_location','phone','email','event_time']
        for(let key in UserDetails){
               
         if(UserDetails.hasOwnProperty(key)&& validFields.includes(key) && UserDetails[key]){
             if(UserDetails.cartItems.length >= 1){
                 count ++
             }
            }
     } 
     
    if(count <7) { navigate('/products')}
    
    })
    useEffect(()=>{
        document.querySelector('input[value="100"]').click();
        setUserDetails((prevState)=>({...prevState,loading:false}))
    
    },[]);
    
    const totalPrice = sumTotal(UserDetails.cartItems)
    const totalFoodCost = totalPrice;
    const serviceFee = totalFoodCost * 0.1;
    const logisticsFee = 5000;
    const totalLogisticFee = serviceFee + logisticsFee;
  

    const cartConfig = {
        cart:UserDetails.cartItems,
        selectedPercent:Number(UserDetails.selectedPercent)
    };
    
    const paystackConfig ={
           firstname:UserDetails.name,
           phone:UserDetails.phone,
            
            email:UserDetails.email,
            amount:(UserDetails.percentPrice)*100,
            publicKey :key
        };
    const onSuccess = (reference) => {
        
        
        setUserDetails((prevState)=>({...prevState,loading:true,loadingText:'Verifying transaction'}))
        const token = window.sessionStorage.getItem('token')
        const userObject = {
            name:UserDetails.name,
            email:UserDetails.email,
            eventDate:UserDetails.event_date,
            time:convert24hourTo12HourFormat(UserDetails.event_time),
            phone:UserDetails.phone,
            event_type:UserDetails.event_type,
            event_location:UserDetails.event_location
        }
        VerifyTransaction(reference.reference,UserDetails.cartItems,token,UserDetails.selectedPercent,userObject,navigate)
    };

    const onClose = () => {
        setUserDetails((prevState)=>({...prevState,loading:false}))
    }

    const intializePayment = usePaystackPayment(paystackConfig);

    const validatCart = async (items,callback) => {

        setUserDetails((prevState)=>({...prevState,loading:true,loadingText:'Loading checkout widget'}));

       sendCartData(items,callback)
       .then(function (response) {
        
        if(response.status === 200){
            setUserDetails((prevState)=>({...prevState,percentPrice:response.data.price}));
            window.sessionStorage.setItem('token',response.data.token)
            setTimeout(()=>{intializePayment(onSuccess,onClose)},3000)
            
        }
      })
      .catch(function (error) {
          
     navigate('/error');
        
      });
    
    
    }
    
    
    
    return ( <>
    <CartHero imageBg={checkoutHero} />
    <div className=" container px-6 py-10 mx-auto ">
    <div className='cart-bg cart-card' style={{background:'transparent',border:'none'}}>
    <h3 id="total-title">TOTAL COST TO BE PAID</h3>
    <div><p className="cart-title">TOTAL COST:</p> <p className="cart-subtitle">{`₦${totalPrice}` || '₦467,000'}</p></div>
    <div><p className="cart-title"><s>DISCOUNT:</s></p><p className="cart-subtitle"><s>{`₦${totalLogisticFee}` || '₦47,000'}</s></p></div>
    <p className="discount">We give service and logistics fee as our first-timer discount. You’ll only pay for the food ordered, we will take care of the service and logistics fee ourselves.</p>
    </div>
    <Radio />
    <hr />
    <div id="total"><p className="cart-title">Part-payment  Amount:</p><p className="cart-subtitle">{`₦${UserDetails.percentPrice}` || '₦47,000'}</p></div>
    <div id="total"><p className="cart-title">Amount Remaning:</p><p className="cart-subtitle">{`₦${totalPrice-UserDetails.percentPrice}` || '₦47,000'}</p></div>
    <div id="total"><p className="cart-title">TOTAL:</p><p className="cart-subtitle">{`₦${totalPrice}` || '₦47,000'}</p></div>
    <hr />
    <div className=' custom-button flex item-center justify-center  py-6' >
           <Button5 bg border onClick={()=>navigate('/cart')}><i class="bi bi-arrow-left" style={{fontSize: '2rem', color: 'black', marginRight:'12px'}}></i> GO BACK</Button5>
           <Button5 onClick={()=>validatCart(cartConfig)} >PROCEED TO MAKE PAYMENT<span >< i class="bi bi-arrow-right" style={{fontSize: '2rem', color: 'black',marginLeft:'12px'}}></i></span></Button5>
           
       </div>
       
    </div>
    
       <Footers />
    </> 
    );
}
 
export default Checkout;