import '../../styles/Booking.css'
import React, { useContext, useState } from 'react';
import { UserContext, UserDispatchContext } from './UserProvider';
import SelectField from './SelectField';
import {Input, Input2,Input3} from './Input';
import {useNavigate} from 'react-router-dom' ;
import DatePicker from "react-datepicker";  
  
import "react-datepicker/dist/react-datepicker.css";
import Alert from './Alert';  
import Modal from '../Products/Modal';
const Booking = ()=>{
    const UserDetails = React.useContext(UserContext);

const setUserDetails = useContext(UserDispatchContext);
  const [err,setErr] = useState(false);
    const [startDate, setStartDate] = useState();  
    const navigate = useNavigate()
    const validFields = ['name','event_type','event_date', 'event_location','phone','email','event_time']
    const submitHandler = () => {
      let count = 0
      for(let key in UserDetails){
          
          if(UserDetails[key] && validFields.includes(key)){
              count++
          }
      } 
      
     count >=7?navigate('/products'):setUserDetails((prevState) =>({...prevState,err:true}))
    //navigate('/products')
    //console.log(UserDetails)
    }
const setInputvalue = (e) =>{
    setUserDetails((prevState) =>({...prevState,event_date:e.toLocaleDateString('en-US',{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}))
  //console.log(UserDetails)
}

    return(
        <>
        <div className='md:w-3/4  sm:w-3/4  social ' style={{display:'flex',justifyContent:'center',alignItems:'center',position:'relative'}}>
         <div className='booking '>
         <Modal title={'Error'} text="All fields are required" />
             <h4 id="booking">BOOKINGS</h4>
            
             <div className='w-full flex flex-wrap -mx-3 mb-2'>

             <SelectField name="event_location" options={['Family Enagagement','Religious Event', 'Coporate Event','Personal needs']}/> 
             <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0"><DatePicker 
             className='form-control p-5 block appearance-none w-full bg-white-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8  leading-tight focus:outline-none focus:bg-white focus:border-gray-500' 
             placeholderText='Date of Event' 
             selected={startDate} onChange={(date) =>{
              setInputvalue(date)
              setStartDate(date)
             }}/> 
             
             </div>
           
             <Input2 type="text" name="event_time" placeholder="TIme of event"/>  
             </div>
             
             <div class="details w-full  flex flex-wrap -mx-3 mb-2 mt-5 sm:mt-2">
              <div className='md:w-1/4 sm:w-full'><Input type="text" name="name" placeholder="Name"/> </div>
              <div className='md:w-1/4 sm:w-full'><Input type="email" name="email" placeholder="Email address"/> </div>   
              <div className='md:w-1/4 sm:w-full'><Input type="text" name="event_location" placeholder="Location"/></div>
              <div className='md:w-1/4 sm:w-full'><Input3 type="phone" name="phone" placeholder="Phone"/></div>

             </div>
             <button id="next-Btn" className='hero-btn' onClick={submitHandler} >Continue <i className="fa fa-arrow-right"></i></button>
             
         </div>
       

        </div>
       
        </>
    )
};

export default Booking;