import React, { createContext, useState } from "react";
const UserContext = createContext(undefined);
const UserDispatchContext = createContext(undefined);


function UserProvider({ children }) {
  const [userDetails, setUserDetails] = useState({
    name: "",
    email:"",
    event_type:"Family Enagagement",
    event_date:"",
    event_location:"",
    phone:"",
    event_time:"",
    price:0,
    cartItems:[],
    err:false,
    percentPrice:0,
    selectedPercent:'',
    loading:false,
    loadingText:'Loading checkout widget'
  });

  return (
    <UserContext.Provider value={userDetails}>
      <UserDispatchContext.Provider value={setUserDetails}>
        {children}
      </UserDispatchContext.Provider>
    </UserContext.Provider>
  );
}

export { UserProvider, UserContext, UserDispatchContext };